<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation', 'nameplate_image', 'status']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
        >
            <template slot="nameplate_image" slot-scope="data">
                <LookImages
                    v-if="data.text.length"
                    :style_img="{ width: '40px', height: '40px' }"
                    :list="data.text"
                ></LookImages>
                <span v-else>无图片</span>
            </template>
            <template slot="status" slot-scope="data">
                <span v-for="item in status_list" :key="item.key">
                    <a-tag
                        :color="
                            item.key == 1
                                ? config.color.primary
                                : item.key == -1
                                ? config.color.error
                                : config.color.warning
                        "
                        v-if="data.text == item.key"
                        >{{ item.value }}</a-tag
                    >
                </span>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a
                        v-if="data.text.status == 0"
                        v-auth="'examine'"
                        @click="edit(data.record)"
                        >审核</a
                    >
                </span>
            </template>
        </TableList>
        <EditPop
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getExamineList, bingingExamine } from "@/api/customer";
import LookImages from '@/components/LookImages'

let columns = [
    {
        title: "部门",
        dataIndex: "department"
    },
    {
        title: "品项名称",
        dataIndex: "goods_title",

    },
    {
        title: "型号",
        dataIndex: "goods_model",
    },
    {
        title: "资产编号",
        dataIndex: "goods_code",
    },
    {
        title: "生产日期",
        dataIndex: "make_time",
    },
    {
        title: "押金",
        dataIndex: "deposit",
    },
    {
        title: "图片",
        dataIndex: "nameplate_image",
        scopedSlots: {
            customRender: "nameplate_image"
        }
    },
    {
        title: '备注',
        dataIndex: "reason",
    },
    {
        title: "投放客户",
        dataIndex: "customer_name",
    },

    {
        title: "审核人",
        dataIndex: "examine",
        // scopedSlots: {
        //     customRender: "address"
        // }
    },

    {
        title: "业务员",
        dataIndex: "username",
    },
    {
        title: "状态",
		dataIndex: "status",
		scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];
const status_list = [
    {
        key: 0,
        value: '待审核'
    },
    {
        key: -1,
        value: '拒绝'
    },
    {
        key: 1,
        value: '通过'
    }
]
export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        LookImages,
        TableList
    },
    data() {
        return {
            get_table_list: getExamineList,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: 'select',
                        title: '状态',
                        name: 'status',
                        options: {},
                        list: status_list
                    },
                ],
                ...this.$config.form_data_seo
            },
			status_list: status_list,
			config:this.$config,
            form_data: {
                title: '审核',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'radio',
                        name: 'status',
                        list: [
                            {
                                key: 1,
                                value: '通过'
                            },
                            {
                                key: -1,
                                value: '拒绝'
                            },
                        ],
                        options: {},
                    }
                ]
            }
        };
    },
    async created() {

        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
    },
    methods: {
        edit(record) {
            this.form_data.title = "审核"
            let data = record
            this.form_data.list.forEach(item => {
                item.options.initialValue = data[item.name];
            })
            this.visible = true;
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            bingingExamine({
                data: {
                    ...data
                },
            }).then(res => {
                this.$message.success(res.info);
                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>